<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Số báo cáo (*)<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Số báo cáo (*)"
              rules="required"
              :custom-messages="{required: `Số báo cáo (*) là bắt buộc`}"
            >
              <b-form-input
                v-model="dataGeneral.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Ngày báo cáo<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Ngày báo cáo"
              rules="required"
              :custom-messages="{required: `Ngày báo cáo là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataGeneral.dateReport"
                :disabledInput="true"
                :state="errors.length > 0 ? false : null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          xl="4"
          lg="4"
          md="12"
          sm="12"
        >
          <b-form-group>
            <label>Năm báo cáo<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Số báo cáo"
              rules="required"
              :custom-messages="{required: `Số báo cáo là bắt buộc`}"
            >
              <b-form-input
                v-model="dataGeneral.year"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, numeric, email,
} from '@validations'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    DateTimePicker,
  },

  props: {
    dataGeneral: {
      type: Object,
      default: _ => {},
    },
  },

  data() {
    return {
      disableProp: false,
    }
  },
}
</script>

<style>

</style>
